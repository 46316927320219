import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Layout from "./layout"

import logo from '../images/logo.png';

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#263696`,
      marginBottom: `1.45rem`,
    }}
    className={"c-header"}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
      }}
    >
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
      >
        <img src={logo} alt={siteTitle} className={"c-logo__img"} />
      </Link>


      <ul className={"c-menu"}>
        <li className={"c-menu__item"}><Link className={"c-menu__link"} to="/">Home</Link></li>
        <li className={"c-menu__item"}><Link className={"c-menu__link"} to="/realisaties">Realisaties</Link></li>
        <li className={"c-menu__item"}><Link className={"c-menu__link"} to="/contact">Contact</Link></li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
