/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"

const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>

      </div>
      <footer className={"c-footer"}>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
          }}
        >
          <div>
            <strong>Adres</strong><br />
            Halstraat 23<br />
        3530 Houthalen-Helchteren<br />
        <br />
        BTW BE 0746.432.618
          </div>
          <div>
            <strong>Mail</strong><br />
            info@msbouwwerken.be
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
